@font-face {
  font-family: "ProximaNovaRegular";
  src: local("Mark Simonson Proxima Nova Regular"),
    url("../assets/font/Mark\ Simonson\ \ Proxima\ Nova\ Regular.otf") format("opentype");
}

html,
body {
  // height: 100%;
  font-family: "ProximaNovaRegular";
}

body {
  overflow-x: auto;

  .main {
    font-size: 25px;
  }

  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background-color: #f0f0f0 !important;
  }

  .sub_head {
    font-size: 16px;
    font-family: "ProximaNovaRegular";
    color: #767676;
  }

  .sub_value {
    font-size: 16px;
    font-family: "ProximaNovaRegular";
    font-weight: 600;
  }
  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background: transparent !important;
  }

  .adapter_table {
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    margin-top: 10px;
  }
  .adapter-energy-table {
    width: 100%;
    & .adapter-energy-tr {
      border-bottom: 1px solid #dcdcdc;
    }
    & .adapter-energy-td {
      padding: 2%;
      padding-left: 20px;
      word-wrap: break-word;
      word-break: break-all;
      font-size: 14px;
    }
  }
}
