$app-color: #252e65;
$white-color: #ffffff;
$base-font-size: 14px;

// === COLORS ===
$bg-color: #ffffff;
$primary-color: #252e65;
$primarytext-color: #333333;
$grey-color: #9facbb;
$secondarytext-color: #063267;
$warningtext-color: #a72121;
$muiicon-color: #252e65;
$secondary-color: #50b737;
$textoff-color: #2e4765;
$link-low: #01279c;
$blue-color: #1b87f7;